import React from 'react';
import PropTypes from 'prop-types';
import styled from 'theme/libs/styled';

import SVG from './base/SVG';

const RotatingSVG = styled(SVG)`
    animation: rotate 1.2s linear infinite;

    @keyframes rotate {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(270deg);
        }
    }
`;

const AnimatedCircle = styled('circle')`
    stroke-dasharray: 187;
    stroke-dashoffset: 60;
    transform-origin: center;
    animation: animate 1.2s ease-in-out infinite;

    @keyframes animate {
        0% {
            stroke-dashoffset: 187;
        }
        50% {
            stroke-dashoffset: 10;
            transform: rotate(135deg);
        }
        100% {
            stroke-dashoffset: 187;
            transform: rotate(450deg);
        }
    }
`;

const SearchIcon = ({ color, ...props }) => (
    <RotatingSVG viewBox="0 0 66 66" {...props}>
        <AnimatedCircle stroke={color} fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30" />
    </RotatingSVG>
);

SearchIcon.propTypes = {
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

SearchIcon.defaultProps = {
    color: 'currentColor',
    height: '40px',
    width: '40px',
};

export default SearchIcon;
