import { getState } from 'core-web/state';
import discountInPercentage from 'core-web/util/discountInPercentage';

const showRecommendedPrice = (priceExVat, recommendedPriceExVat, priceSetting) => {
    if (!priceSetting) {
        const application = getState('application');
        priceSetting = {
            hideRecommendedPrice: application?.config?.options?.pricing?.hide_recommended,
            recPriceSettings: application?.config?.options?.pricing?.rec_price_settings,
        };
    }
    const percentage = discountInPercentage(recommendedPriceExVat, priceExVat);
    if (priceSetting.hideRecommendedPrice) {
        return false;
    }
    if (!priceSetting.recPriceSettings) {
        if (parseInt(percentage, 10) >= 1) {
            return true;
        }
        return false;
    }
    const { price_x: priceX, percentage_y: percentageY, percentage_z: percentageZ } = priceSetting.recPriceSettings;
    if (priceExVat < priceX && percentage > percentageY) {
        return true;
    } else if (priceExVat > priceX && percentage > percentageZ) {
        return true;
    }
    return false;
};

export { showRecommendedPrice };
