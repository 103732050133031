import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Events from 'core-web/libs/Events';
import { SaleEvents } from 'core-web/libs/Events/constants';
import { GetProductByPartNo } from 'core-web/libs/GrebbCommerceAPI/Products';
import { getState, injectModels } from 'core-web/state';
import getDropshipInfo from 'core-web/util/getDropshipInfo';
import getPromisedShippingDate from 'core-web/util/getPromisedShippingDate';
import { getFlag } from 'core-web/containers/ProductPageContainer';
import {
    DROPSHIP_MARKET_SPECIFIC,
    PRODUCT_DROPSHIP,
    PRODUCT_PREORDER,
    STORM_PACKAGE_TYPES,
} from 'theme/config/constants';
import RoundedButton from 'theme/components/buttons/RoundedButton';
import SpinnerIcon from 'theme/components/icons/SpinnerIcon';

const AddToBasketButton = ({
    basket,
    isBasketUpsell,
    isCheckoutUpsell,
    objectId,
    disabled,
    children,
    quantity,
    overlay,
    upsellProductData,
    page,
    stockStatus,
    ...props
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();
    const upsellType = isBasketUpsell ? 'basket' : isCheckoutUpsell ? 'checkout' : null;
    const locale = getState('application')?.locale;
    const infos = {};

    const addToBasket = async () => {
        if (isLoading) {
            return;
        }
        setIsLoading(true);

        try {
            const product = !upsellProductData && (await GetProductByPartNo(objectId));
            const mainProduct = upsellProductData || product.data;
            const isPackage = STORM_PACKAGE_TYPES.indexOf(mainProduct.type) !== -1;
            const graduallyQty = product?.data?.recommended_quantity;

            let leadTime = stockStatus?.leadTime || 0;
            const hasPreBookFlag = !!getFlag(PRODUCT_PREORDER, product);
            const shouldAddMaxDays = !stockStatus?.nextDelivery && hasPreBookFlag;

            infos['promised_shipping_date'] = getPromisedShippingDate(leadTime, locale, stockStatus, shouldAddMaxDays);

            if (isPackage) {
                const mainItem = {
                    'part_no': mainProduct.part_no,
                    quantity: (mainProduct.quantity || 1) * quantity,
                    'price_list_id': mainProduct.price_list_id,
                };
                if (getDropshipInfo(PRODUCT_DROPSHIP, DROPSHIP_MARKET_SPECIFIC, mainProduct, locale)) {
                    mainItem['dropship'] = 'true';
                }
                const subItems = mainProduct.included_products.map(product => {
                    const data = {
                        'part_no': product.part_no,
                        quantity: (product.quantity || 1) * quantity,
                        'price_list_id': product.price_list_id,
                    };
                    if (getDropshipInfo(PRODUCT_DROPSHIP, DROPSHIP_MARKET_SPECIFIC, product, locale)) {
                        data['dropship'] = 'true';
                    }
                    return data;
                });

                const items = [mainItem, ...subItems];
                await basket.addPackageToBasket(items, {
                    ...mainProduct,
                    quantity: (mainProduct.quantity || 1) * quantity,
                });
            } else {
                if (getDropshipInfo(PRODUCT_DROPSHIP, DROPSHIP_MARKET_SPECIFIC, mainProduct, locale)) {
                    infos.dropship = 'true';
                }
                await basket.addToBasket(
                    mainProduct.part_no,
                    graduallyQty || quantity,
                    mainProduct.price_list_id,
                    infos
                );
            }

            if (upsellType) {
                await Events.trigger(SaleEvents.UPSELL_PRODUCT_ADDED, { product: mainProduct, upsellType });
            }
        } catch (e) {
            console.error(e);
        }
        page.template !== 'checkout' && overlay.show('basket_overlay');

        setIsLoading(false);
    };

    return (
        <RoundedButton disabled={disabled} onClick={addToBasket} {...props}>
            {isLoading ? (
                <SpinnerIcon width={['16px', null, null, '24px']} height={['16px', null, null, '24px']} />
            ) : (
                children || t('add')
            )}
        </RoundedButton>
    );
};

AddToBasketButton.propTypes = {
    basket: PropTypes.object.isRequired,
    children: PropTypes.node,
    disabled: PropTypes.bool,
    isBasketUpsell: PropTypes.bool,
    isCheckoutUpsell: PropTypes.bool,
    objectId: PropTypes.string.isRequired,
    overlay: PropTypes.object.isRequired,
    page: PropTypes.object.isRequired,
    quantity: PropTypes.number,
    upsellProductData: PropTypes.object,
    stockStatus: PropTypes.object,
};

AddToBasketButton.defaultProps = {
    children: null,
    disabled: false,
    isBasketUpsell: false,
    isCheckoutUpsell: false,
    quantity: 1,
    upsellProductData: null,
    stockStatus: {},
};

export default injectModels(['basket', 'overlay', 'page'])(AddToBasketButton);
