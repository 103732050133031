import { getFlag } from '../util/getFlag';

const getDropshipInfo = (flag: string[] | string, flagObject: object, product: any, locale: string) => {
     const productFlags = product?.format?.flags || [];
    const hasGlobalFlag = getFlag(flag, productFlags);
    
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const hasMarketFlag = getFlag(flagObject[locale], productFlags); 
    return !!(hasGlobalFlag || hasMarketFlag);
};

export default getDropshipInfo;
