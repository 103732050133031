import React from 'react';
import PropTypes from 'prop-types';
import colors from 'theme/config/colors';
import SVG from './base/SVG';

const DeliveryTruckIcon = ({ color, ...props }) => (
    <SVG fill="none" viewBox="0 0 26 14" {...props}>
        <path
            fill={color}
            d="M24.86 3.21a3.8 3.8 0 00-2.82-1.16h-2.67V.63a.64.64 0 00-.64-.63H5.98a.64.64 0 00-.64.63v2.19H2.86a.64.64 0 00-.64.63c0 .34.29.62.64.62h3.12c.36 0 .65-.28.65-.62v-2.2H18.1v9.85h-6.02l-.08.01a2.15 2.15 0 00-3.97.01.62.62 0 00-.14-.02H6.63V8.91a.64.64 0 00-.64-.63H2.86a.64.64 0 00-.64.63c0 .35.29.63.64.63h2.48v2.19c0 .34.3.63.64.63h1.94a2.13 2.13 0 002.1 1.64c1.02 0 1.87-.7 2.09-1.64h7.65A2.14 2.14 0 0021.85 14c1.02 0 1.87-.7 2.09-1.64h1.42c.35 0 .64-.29.64-.63V6.5c0-1.36-.4-2.5-1.14-3.3zm-14.84 9.54a.86.86 0 01-.87-.85c0-.48.39-.86.87-.86.47 0 .86.38.86.86 0 .47-.39.85-.86.85zm11.83 0a.86.86 0 01-.87-.85c0-.48.39-.86.87-.86.47 0 .86.38.86.86 0 .47-.4.85-.86.85zm2.87-1.65h-.89a2.15 2.15 0 00-3.97 0h-.49V3.3h2.68c1.67 0 2.67 1.2 2.67 3.21v4.6z"
        />
        <path
            fill={color}
            d="M10.96 7.36a.62.62 0 000 .89.65.65 0 00.9 0l1.65-1.62.04-.05.02-.03.02-.02.02-.03.01-.03.02-.03.01-.03.01-.03.01-.02v-.04l.01-.03v-.03l.01-.03a.5.5 0 000-.13v-.02-.04l-.01-.03v-.03l-.02-.03-.01-.03-.01-.02-.02-.04-.01-.02-.02-.03c0-.01 0-.02-.02-.03l-.02-.03-.03-.04-1.57-1.54a.65.65 0 00-.9 0 .62.62 0 000 .89l.47.46H.64a.64.64 0 00-.64.63c0 .35.29.63.64.63H11.5l-.55.58z"
        />
    </SVG>
);

DeliveryTruckIcon.propTypes = {
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    margin: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

DeliveryTruckIcon.defaultProps = {
    color: colors.green,
    height: '12px',
    margin: ['0 8px 3px 0', '0 6px 3px 0', '0 8px 3px 0'],
    width: ['24px', '18px', '24px'],
};

export default DeliveryTruckIcon;
