const discountInPercentage = (originalPrice, salePrice, euroMarket = false) => {
    if (!originalPrice || !salePrice) {
        return null;
    }

    const diff = originalPrice - salePrice;
    const amount = diff / originalPrice;

    // Showing discounts above 1% equals to 0.1% in EUR removing rounding to avoid 0%
    if (euroMarket) {
        return amount * 100;
    }

    return Math.round(amount * 100);
};

export default discountInPercentage;
