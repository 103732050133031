import { numberOfDaysInclusive, numberOfWeekends } from 'core-web/util/calcDates';
import formatDate from 'core-web/util/formatDate';

interface StockStatus {
    nextDelivery?: string;
    stock: number;
}

/**
 * On products we show: ships in 1-3 days, 2-4 days etc
 * Basically leadTime (data from product) + 2 since we want to set the latest date as shipping date
 * So calculation SHOULD be: date of order (1 day) + leadtime + 1 day
 *
 * @param {number} leadTime Number of lead time days
 * @param {string} locale Current application locale, for formatting date
 * @param {boolean} addMaxDays
 * If there is no available delivery date for products with prebooking,
 * add 179 days to promised shipping date.
 * Max amount of days allowed by Walley.
 *
 * @returns {string} Formatted date
 */
export default (leadTime: number, locale: string, stockStatus: StockStatus, addMaxDays = false) => {
    const currentDate = new Date();
    let addWeekends = true;

    /**
     * If there is no available delivery date for products with prebooking,
     * add 179 days to promised shipping date.
     * Max amount of days allowed by Walley.
     */
    if (addMaxDays && stockStatus.stock <= 0) {
        currentDate.setDate(currentDate.getDate() + 179);

        /**
         * Adjust date to last friday if the resulting day is a saturday or sunday.
         * We can't adjust forward since the absolute max number of days is 179.
         */
        if (currentDate.getDay() === 0) {
            currentDate.setDate(currentDate.getDate() - 2); // Adjust to previous Friday
        } else if (currentDate.getDay() === 6) {
            currentDate.setDate(currentDate.getDate() - 1); // Adjust to previous Friday
        }

        return formatDate(currentDate, 'yyyy-MM-dd', locale);
    }

    if (leadTime === 0 && !!stockStatus?.nextDelivery) {
        const dateNow = new Date(Date.now());
        const nextDelivery = new Date(stockStatus.nextDelivery);

        leadTime = numberOfDaysInclusive(dateNow, nextDelivery);
        addWeekends = false;
    }

    const buffer = leadTime > 0 ? 2 : 1;

    const promisedShippingDate = new Date(currentDate);
    promisedShippingDate.setDate(currentDate.getDate() + leadTime + buffer);

    if (addWeekends) {
        // Adjust for weekends
        const weekends = numberOfWeekends(currentDate, promisedShippingDate);
        promisedShippingDate.setDate(promisedShippingDate.getDate() + weekends);
    }

    // Adjust for Saturday and Sunday
    if (promisedShippingDate.getDay() === 0) {
        promisedShippingDate.setDate(promisedShippingDate.getDate() + 1); // Move to Monday
    } else if (promisedShippingDate.getDay() === 6) {
        promisedShippingDate.setDate(promisedShippingDate.getDate() + 2); // Move to Monday
    }

    return formatDate(promisedShippingDate, 'yyyy-MM-dd', locale);
};
